import { useCallback } from "react";
import { useQuery } from "react-query";
import {
  getFaqArticle,
  rateArticle,
  deleteFaqArticle,
  getDraftArticle,
  revertFaqArticle,
} from "../api/faqArticleHttpClient";
import { useSnackbar } from "notistack";
import { publishDraft } from "../api/publishDraftHttpClient";
import { useNavigate } from "react-router-dom";
import routes from "../../../Common/consts/routes";

export const useFaqArticle = (id: string) => {
  const getFaqArticleCallback = useCallback(() => getFaqArticle(id), [id]);
  const getDraftArticleCallback = useCallback(() => getDraftArticle(id), [id]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { data: faqArticle, isLoading } = useQuery(
    ["faq_article", id],
    getFaqArticleCallback
  );

  const { data: draftArticle, isLoading: isLoadingDraft } = useQuery(
    ["parent_id", id],
    getDraftArticleCallback
  );

  const submitRating = async (value: boolean) => {
    await rateArticle(id, value);
    enqueueSnackbar("Thank you for rating the article!", {
      variant: "success",
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });
  };

  const submitDeleteArticle = async (id: string) => {
    await deleteFaqArticle(id);
    enqueueSnackbar("FAQ article has been deleted!", {
      variant: "success",
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });

    navigate(routes.faq);
    window.location.reload();
  };

  const submitPublish = async () => {
    try {
      await publishDraft(id);

      enqueueSnackbar("Publish FAQ successfully", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    } catch {
      enqueueSnackbar("Something went wrong while publishing FAQ", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    } finally {
      window.location.reload();
    }
  };

  const submitRevert = async () => {
    await revertFaqArticle(id);
    enqueueSnackbar("FAQ article has been reverted!", {
      variant: "success",
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });

    navigate(routes.faq);
    window.location.reload();
  };

  return {
    faqArticle,
    isLoading,
    submitRating,
    submitDeleteArticle,
    submitPublish,
    submitRevert,
    draftArticle,
    isLoadingDraft,
  };
};
