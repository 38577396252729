import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

interface FaqArticleModalWindowProps {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  dialogTitle: string;
  dialogText: string;
  confirmButtonText: string;
  confirmationButtonColor?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
}

export const FaqArticleModalWindow = ({
  open,
  handleClose,
  handleConfirm,
  dialogTitle,
  dialogText,
  confirmButtonText,
  confirmationButtonColor,
}: FaqArticleModalWindowProps): JSX.Element => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "900px",
          maxWidth: "none",
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleConfirm}
          autoFocus
          color={confirmationButtonColor || "error"}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
