// FaqArticleForm.tsx
import { Backdrop, Button } from "@mui/material";
import styles from "./FaqArticleForm.module.scss";
import { UseFormReturn } from "react-hook-form";
import HeiTextField from "../../../../Common/components/Form/HeiTextField/HeiTextField";
import HeiRichTextField from "../../../../Common/components/Form/HeiRichTextField/HeiRichTextField";
import HeiSelection from "../../../../Common/components/HeiSelection/HeiSelection";
import { faqToolOptions } from "../../../../Common/consts/faqToolOptions";
import { FaqArticleFormType } from "../../models/FaqArticle";
import HeiDatePicker from "../../../../Common/components/Form/HeiDatePicker/HeiDatePicker";

interface FaqArticleFormProps {
  form: UseFormReturn<FaqArticleFormType, any>;
  isLoading: boolean;
  submit: () => void;
  handleCancel: () => void;
  actionButtonLabel: string;
}

const FaqArticleForm = ({
  form,
  isLoading,
  submit,
  handleCancel,
  actionButtonLabel,
}: FaqArticleFormProps) => {
  const { control, getValues } = form;

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      ></Backdrop>
      <div className={styles.faqView}>
        <HeiTextField
          name={"title"}
          label="Title"
          control={control}
          rules={{
            required: "Title is required",
          }}
        />
        <HeiTextField
          name={"synopsis"}
          label="Synopsis"
          control={control}
          rules={{
            required: "Synopsis is required",
          }}
        />
        <HeiSelection
          options={faqToolOptions.map((f) => f.toolName)}
          getValues={getValues}
          fieldName={"tools"}
          label="Tools"
          multiple
          control={control}
        />
        <HeiTextField
          name={"alertTitle"}
          label="Alert title"
          control={control}
        />
        <HeiTextField name={"alertBody"} label="Alert body" control={control} />
        <HeiDatePicker
          name={"publicationDate"}
          label="Publication date"
          control={control}
          rules={{
            required: "Publication date is required",
          }}
        />
        <HeiDatePicker name={"endDate"} label="End date" control={control} />
        <HeiRichTextField
          name={"article"}
          label="Article"
          control={control}
          rules={{
            required: "Article is required",
            minLength: 10,
          }}
        />
        <div className={styles.actionButtons}>
          <Button onClick={submit} variant="contained">
            {actionButtonLabel}
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </div>
      </div>
    </>
  );
};

export default FaqArticleForm;
