import axios from "axios";
import { FaqArticleResponse } from "./models/FaqArticleResponse";

export const getFaqArticle = async (
  faqArticleId: string
): Promise<FaqArticleResponse> => {
  const { data: faqArticleResponse } = await axios.get<FaqArticleResponse>(
    `${window.configuration.apiUrl}api/faq/${faqArticleId}`
  );
  return faqArticleResponse;
};

export const deleteFaqArticle = async (faqArticleId: string): Promise<void> => {
  await axios.delete(`${window.configuration.apiUrl}api/faq/${faqArticleId}`);
};

export const rateArticle = async (
  articleId: string,
  isHelpful: boolean
): Promise<void> => {
  await axios.post(
    `${window.configuration.apiUrl}api/Faq/${articleId}/rating`,
    {
      isHelpful,
    }
  );
};

export const getDraftArticle = async (
  parentId: string
): Promise<FaqArticleResponse> => {
  const { data: draftArticleResponse } = await axios.get<FaqArticleResponse>(
    `${window.configuration.apiUrl}api/faq/${parentId}/draft`
  );
  return draftArticleResponse;
};

export const revertFaqArticle = async (faqArticleId: string): Promise<void> => {
  await axios.post(
    `${window.configuration.apiUrl}api/faq/${faqArticleId}/draft/revert`
  );
};
