import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Link,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectHeiUser } from "../../../Auth/store/accountSlice";
import usePageTitle from "../../../Common/hooks/usePageTitle";
import TopBar from "../../../Layout/components/TopBar/TopBar";
import UpdateFaqArticle from "../../UpdateFaqArticle/views/UpdateFaqArticle";
import FaqArticleBody from "../components/FaqArticleBody/FaqArticleBody";
import { useFaqArticle } from "../hooks/useFaqArticle";
import styles from "./FaqArticle.module.scss";
import { FaqArticleModalWindow } from "../../Common/components/FaqArticleModalWindow/FaqArticleModalWindow";
import TitleWithDraft from "../../Common/components/TitleWithDraft";
import routes from "../../../Common/consts/routes";

const FaqArticle = () => {
  const { id } = useParams();
  const {
    faqArticle,
    isLoading,
    submitRating,
    submitDeleteArticle,
    submitPublish,
    submitRevert,
    draftArticle,
  } = useFaqArticle(id as string);
  usePageTitle(`FAQ | ${faqArticle?.faqArticle.title}`);

  const navigate = useNavigate();

  const heiUser = useSelector(selectHeiUser);

  const isAdmin = useMemo(
    () => heiUser.roles.some((r) => r === "Admin"),
    [heiUser]
  );

  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [isRevertModalOpen, setIsRevertModalOpen] = useState(false);

  const isFaqArticleDraft = faqArticle?.faqArticle.isDraft;

  const faqArticleTitle = isFaqArticleDraft ? (
    <TitleWithDraft>{faqArticle?.faqArticle.title}</TitleWithDraft>
  ) : draftArticle ? (
    <TitleWithDraft>{draftArticle?.faqArticle.title}</TitleWithDraft>
  ) : (
    faqArticle?.faqArticle.title
  );

  const faqArticleHasParent = faqArticle?.faqArticle.parentId;

  const onUpdateArticleClick = () => {
    setIsInEditMode(true);
    const url = draftArticle
      ? `${routes.faq}/${draftArticle?.faqArticle.id}`
      : `${routes.faq}/${faqArticle?.faqArticle.id}`;
    navigate(url);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <TopBar>
        <div className={styles.topBar}>
          <Box>
            {!isInEditMode && (
              <>
                <Link
                  href="#"
                  underline="none"
                  onClick={() => navigate(routes.faq)}
                >
                  <div className={styles.faqArticleBar}>
                    <ArrowBackOutlinedIcon fontSize={"small"} />
                    <span>Back</span>
                  </div>
                </Link>
                <Typography variant="h4">
                  {isFaqArticleDraft ? (
                    <TitleWithDraft>
                      {faqArticle?.faqArticle.title}
                    </TitleWithDraft>
                  ) : (
                    faqArticle?.faqArticle.title
                  )}
                </Typography>
              </>
            )}

            {isInEditMode && (
              <Typography variant="h4">{faqArticleTitle}</Typography>
            )}
          </Box>
          <Box>
            {isAdmin && !isInEditMode && (
              <>
                {isFaqArticleDraft && (
                  <>
                    <Button
                      color="info"
                      onClick={() => setIsPublishModalOpen(true)}
                    >
                      PUBLISH Draft
                    </Button>
                  </>
                )}
              </>
            )}
            {isAdmin && faqArticleHasParent && (
              <Button color="error" onClick={() => setIsRevertModalOpen(true)}>
                REVERT Draft
              </Button>
            )}
            {isAdmin && !isInEditMode && (
              <Button onClick={onUpdateArticleClick}>UPDATE Article</Button>
            )}
            {isAdmin && (
              <Button
                color="error"
                onClick={() => setIsDeleteUserModalOpen(true)}
              >
                DELETE Article
              </Button>
            )}
          </Box>
        </div>
      </TopBar>

      <FaqArticleModalWindow
        dialogTitle="Confirm deletion"
        dialogText="Are you sure you want to delete this FAQ article?"
        confirmButtonText="Delete"
        confirmationButtonColor="error"
        open={isDeleteUserModalOpen}
        handleClose={() => setIsDeleteUserModalOpen(false)}
        handleConfirm={() => submitDeleteArticle(id as string)}
      />

      <FaqArticleModalWindow
        dialogTitle="Confirm publication"
        dialogText="Are you sure you want to publish this FAQ article?"
        confirmButtonText="Publish"
        confirmationButtonColor="info"
        open={isPublishModalOpen}
        handleClose={() => setIsPublishModalOpen(false)}
        handleConfirm={() => submitPublish()}
      />

      <FaqArticleModalWindow
        dialogTitle="Confirm reversion"
        dialogText="Are you sure you want to revert this FAQ article?"
        confirmButtonText="Revert"
        open={isRevertModalOpen}
        handleClose={() => setIsRevertModalOpen(false)}
        handleConfirm={() => submitRevert()}
      />

      {faqArticle && !isInEditMode && (
        <div className={styles.faqArticle}>
          <FaqArticleBody faqArticle={faqArticle} submitRating={submitRating} />
        </div>
      )}

      {faqArticle && isInEditMode && (
        <div className={styles.faqArticle}>
          <UpdateFaqArticle
            faqArticle={draftArticle || faqArticle}
            handleCancel={setIsInEditMode}
          />
        </div>
      )}
    </>
  );
};

export default FaqArticle;
