import DraftIcon from "../assets/draft-icon.png";

const TitleWithDraft = ({ children }: { children: React.ReactNode }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    {children}
    <img style={{ marginLeft: "10px" }} src={DraftIcon} alt="Draft Icon" />
  </div>
);

export default TitleWithDraft;
