import { useState } from "react";
import { useForm } from "react-hook-form";
import { updateFaqArticle } from "../api/updateFaqHttpClient";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import routes from "../../../Common/consts/routes";
import { FaqArticleResponse } from "../../FaqArticle/api/models/FaqArticleResponse";
import { FaqArticleFormType } from "../../Common/models/FaqArticle";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

const useUpdateFaq = (faqArticle: FaqArticleResponse) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();

  const values = {
    title: faqArticle.faqArticle.title,
    synopsis: faqArticle.faqArticle.synopsis,
    article: faqArticle.faqArticle.body,
    alertTitle: faqArticle.faqAlertInfo.title ?? "",
    alertBody: faqArticle.faqAlertInfo.body ?? "",
    tools: faqArticle.faqTags ?? [],
    isDraft: faqArticle.faqArticle.isDraft,
    publicationMode: faqArticle.faqArticle.isDraft ? "draft" : "final",
    publicationDate: dayjs(faqArticle.faqArticle.publicationDate),
    endDate: faqArticle.faqArticle.endDate
      ? dayjs(faqArticle.faqArticle.endDate)
      : undefined,
    parentId: faqArticle.faqArticle.parentId,
  };

  const [isLoading, setIsLoading] = useState(false);
  const form = useForm<FaqArticleFormType>({
    defaultValues: values,
    mode: "onBlur",
  });

  const submit = async () => {
    if (!(await form.trigger("publicationDate"))) {
      enqueueSnackbar("Publication date cannot be empty", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      return;
    }

    const formValues = form.getValues();
    dayjs.extend(isSameOrBefore);

    if (
      formValues.endDate &&
      formValues.endDate.isSameOrBefore(formValues.publicationDate, "date")
    ) {
      enqueueSnackbar(
        "End date cannot be earlier or equal to the publication date",
        {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
      return;
    }

    if (await form.trigger()) {
      setIsLoading(true);

      try {
        await updateFaqArticle(formValues, id);

        enqueueSnackbar("Update FAQ successfully", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });

        navigate(`${routes.faq}/${id}`);
      } catch {
        enqueueSnackbar("Something went wrong while updating FAQ", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      } finally {
        setIsLoading(false);
        window.location.reload();
      }
    } else {
      enqueueSnackbar("Please correct the validation errors", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  return { form, submit, isLoading };
};

export default useUpdateFaq;
